// eslint-disable-next-line import/prefer-default-export
export const trackEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  fieldsObject,
) => {
  if (window && window.ga) {
    window.ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue, fieldsObject);
  }
};
