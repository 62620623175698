/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { Paragraph } from '../Typography/Typography';

type ErrorMessageProps = {
  errorText?: string;
}

const StyledErrorMessage = styled.div`
  background-color: ${theme.colors.errorRedBackground};
  padding: ${theme.spacingValues.xxs}px ${theme.spacingValues.xs}px;
  max-width: 420px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${theme.spacingValues.xxs}px;
`;

const StyledErrorIcon = styled.img`
  padding-right: 14px;
  margin: 0;
`;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorText }) => {
  return (
  <StyledErrorMessage>
    <StyledErrorIcon src="/static/error-icon.svg" />
    <Paragraph
      inline
      bold
      marginTop={theme.spacingValues.vs}
      marginBottom={theme.spacingValues.vs}
    >
      {errorText}
    </Paragraph>
  </StyledErrorMessage>
)};
