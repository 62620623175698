/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import theme from '../theme';
import { Paragraph } from '../Typography/Typography';

export type ButtonProps = {
  actionText?: string;
  actionDescription?: string;
  actionColor?: string;
  onClick?: any;
  id?: string;
  disabled?: boolean;
  borderColor?: string;
  marginTop?: number;
  'data-cy'?: string;
  ariaDisabled?: boolean;
  href?: string;
  type?: "button" | "submit" | undefined;
}

export const BasicButton = styled.button`
  display: block;
  text-align: left;
  padding: ${theme.spacing.xs};
  margin-top: ${theme.spacing.xs};
  width: 100%;
  max-width: 345px;
  background-color: ${theme.colors.white};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  :focus {
    outline: ${theme.colors.clickableBlue} auto 2px;
  }
  @media (max-width: 480px) {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    min-width: 250px;
  }
`;

const StyledAnchorButton = styled.a`
  display: block;
  text-align: left;
  text-decoration: none;
  padding: ${theme.spacingValues.xs}px ${theme.spacingValues.xs}px ${theme.spacingValues.xs}px;
  margin-top: ${theme.spacing.xs};
  width: 100%;
  max-width: 345px;
  background-color: ${theme.colors.white};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  :focus {
    outline: ${theme.colors.clickableBlue} auto 2px;
  }
  @media (max-width: 480px) {
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    min-width: 250px;
  }
`;

const StyledConfirmationButton = styled(BasicButton)<ButtonProps>`
  background-color: ${theme.colors.white};
  border-style: solid;
  border-width: 2px;
  border-color: ${({ borderColor }): string => (borderColor || theme.colors.white)};
  :active {
    border: ${({ borderColor }): string => (borderColor || theme.colors.white)} solid 2px;
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

const StyledMoreButton = styled(StyledAnchorButton)<ButtonProps>`
  background-color: ${theme.colors.white};
  display: block;
  margin-top: ${({ marginTop }): number => marginTop || theme.spacingValues.xs}px;
  :active {
    border: ${theme.colors.lightGrey} solid 2px;
    outline: none; 
  }
`;

const StyledActionButton = styled(BasicButton)<ButtonProps>`
  color: ${theme.colors.white};
  background-color: ${({ariaDisabled}): string => ( ariaDisabled ? theme.colors.disabledGreyBackground : theme.colors.clickableBlue)};
  margin-top: ${({ marginTop }): number => marginTop || 0}px;
  :focus {
    border: none;
    outline: ${theme.colors.clickableBlue} auto 2px;
  }
  :active {
    background-color: ${theme.colors.darkBlue};
    border: ${theme.colors.secondaryBlue} solid 2px;
  }
  &:disabled {
    background-color: ${theme.colors.disabledGreyBackground};
  }
`;

const StyledBackButton = styled(StyledAnchorButton)`
  box-shadow: none;
  width: 120px;
  background: none;
  padding: 14px;
  :focus {
    border: none;
    outline: ${theme.colors.clickableBlue} auto 2px;
  }
  :active {
    background-color: ${theme.colors.lightBlue};
    ontline: none;
  }
`;

const StyledArrow = styled.img`
  float: right;
  display: inline;
`;

const StyledBackArrow = styled.img`
  float: left;
  display: inline;
  padding-right: 14px;
  padding-top: 1.5px;
`;

export const ConfirmationButton: React.FC<ButtonProps> = (props) => {
  const {
    actionText, actionDescription, actionColor, borderColor,
  } = props;
  return (
  <StyledConfirmationButton type="button" borderColor={borderColor} {...props} >
    <Paragraph optionalColor={actionColor} bold marginBottom={theme.spacingValues.xxxs}>{actionText}</Paragraph>
    <Paragraph>{actionDescription}</Paragraph>
  </StyledConfirmationButton>
)};

export const MoreButton: React.FC<ButtonProps> = (props) => {
  const { actionText, actionColor, marginTop, href } = props;
  return (
    <Link href={href || '/'} passHref>
      <StyledMoreButton marginTop={marginTop} {...props}>
        <Paragraph optionalColor={actionColor || theme.colors.clickableBlue} bold marginBottom={theme.spacingValues.xxxs} inline>{actionText}</Paragraph>
        <StyledArrow src="/static/right-arrow.svg" alt="" />
      </StyledMoreButton>
    </Link>
)};

export const ActionButton: React.FC<ButtonProps> = (props) => {
  const { actionText, actionColor, marginTop, ariaDisabled } = props;
  const textColor = ariaDisabled ? theme.colors.greyDark60 : actionColor;
  return (
  <StyledActionButton type="button" marginTop={marginTop} ariaDisabled={ariaDisabled} {...props} >
    <Paragraph optionalColor={textColor || theme.colors.white} center bold fontSize={theme.fontSize.xsx}>{actionText}</Paragraph>
  </StyledActionButton>
)};

export const BackButton: React.FC<ButtonProps> = (props) => {
  const { actionColor, href } = props;
  return (
    <Link href={href || '/'} passHref>
      <StyledBackButton {...props}>
        <StyledBackArrow src="/static/back-arrow.svg" alt="" />
        <Paragraph optionalColor={actionColor} bold>Back</Paragraph>
      </StyledBackButton>
    </Link>
  
)};
