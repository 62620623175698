/* eslint-disable import/extensions */
import React from 'react';
import Router from 'next/router';
import axios from 'axios';
import { NextPage, GetServerSideProps } from 'next';

import { LoginToken, AuthenticationErrors } from '@lansw-tracker/common';

import { Paragraph } from '../components/Typography/Typography';
import PageContainer from '../components/PageContainer/PageContainer';
import CodeEntry from '../components/CodeEntry/CodeEntry';
import CookieWarning from '../components/CodeEntry/CookieWarning';
import theme from '../components/theme';
import { HeadingMainSmall } from '../components/Typography/Typography';
import ErrorPage from '../components/Error/ErrorPage';

interface IndexProps {
  urlCode?: string;
  codeGenerationResult?: number;
  authenticationError?: AuthenticationErrors | null;
  error?: { errorCode: number; errorMessage: string } | null;
}

const IndexPage: NextPage<IndexProps> = ({
  urlCode, codeGenerationResult, authenticationError, error,
}) => {

  if (!!error) {
    return <ErrorPage error={error} />
  }

  if (codeGenerationResult === 200) {
    return (
      <PageContainer>
        <HeadingMainSmall marginBottom={theme.spacingValues.m}>Welcome to the Legal Aid NSW Grants Tracker</HeadingMainSmall>
        { urlCode && (
        <CodeEntry
          queryID={urlCode}
          onValidCode={(): void => {
            Router.push('/updates');
          }}
        />
        )}
        <CookieWarning/>
      </PageContainer>
    );
  }

  if (codeGenerationResult === 403) {
    if (authenticationError === AuthenticationErrors.FILE_LOCKED) {
      return (
        <PageContainer>
        <HeadingMainSmall>Welcome to the Legal Aid NSW Grants Tracker</HeadingMainSmall>
        <Paragraph>
          This file has been locked. Please email <a href="mailto:grantstracker@legalaid.nsw.gov.au">grantstracker@legalaid.nsw.gov.au</a> for further assistance.
        </Paragraph>
      </PageContainer>
      );
    }
    if (authenticationError === AuthenticationErrors.NO_CONSENT) {
      return (
        <PageContainer>
          <HeadingMainSmall>Welcome to the Legal Aid NSW Grants Tracker</HeadingMainSmall>
          <Paragraph marginBottom={theme.spacingValues.xs}>
            You are no longer subscribed to receive or view updates about this application in the Legal Aid NSW Grants Tracker.
          </Paragraph>
        </PageContainer>
      );
    }
  }

  return (
    <PageContainer>
      <HeadingMainSmall>Welcome to the Legal Aid NSW Grants Tracker</HeadingMainSmall>
      <Paragraph>
        Legal Aid NSW has sent a link
        to your application to your mobile phone.
      </Paragraph>
      <Paragraph>Please follow the link.</Paragraph>
    </PageContainer>
  );
}

const getUrlCode = (code: string | string[]): string => {
  if (Array.isArray(code)){
    return code[0] ? code[0] : '';
  }
  return code;
}

export const getServerSideProps: GetServerSideProps<IndexProps> = async({ req, res, query }) => {
  const { id: code } = query;
  const { token } = req.signedCookies;
  
  const urlCode: string = code ? getUrlCode(code) : '';
  let codeGenerationResult = 404;
  let authenticationError = null;
  const error = { errorCode: 500, errorMessage: 'Server error' };
  let isError = false;
  
  const redirectTo = (to: string): void => {
    res.setHeader('Location', to );
    res.status(302).end();
  }
  
  if (!!token) {
    try {
      // user is logged in
      const jwtlib = require('jsonwebtoken'); // eslint-disable-line @typescript-eslint/no-var-requires
      const decodedToken: LoginToken = jwtlib.verify(token, process.env.APP_SECRET);
      
      if (urlCode && urlCode !== decodedToken.sub) {
        // they are trying to access a different file than they are logged in for,
        // log them out then move onto checking if they are a valid user
          const response = await axios
            .create({ withCredentials: true })
            .post(`${process.env.apiNew}/auth/logout`);
          res.setHeader('set-cookie', response.headers['set-cookie']);
          redirectTo(`${process.env.BASE_URL}?id=${urlCode}`);
          return { props: {} }
      } else {
        redirectTo(`${process.env.BASE_URL}/updates`);
        // An 'empty' return to stop us processing the rest of it
        // or at least as empty as typescript will allow.
        return { props: {} }
      }
    } catch (ex) {}
  }
  if (!!urlCode) {
    // user is not logged in yet.
    // This API will check to see if the user with this urlCode can log in
    // if so it will have sent them an SMS OTP.
    // if not we need to get third-party confirmation.
    try {
      const response = await axios.post(`${process.env.apiNew}/auth/check-user`, {
        urlCode,
      });
      if (response.data.redirectToConfirmation && response.data.clientName) {
        redirectTo(`${process.env.BASE_URL}/confirmation?id=${urlCode}`);
      } else {
        codeGenerationResult = 200;
      }
    } catch(ex) {
      if (ex.response && ex.response.data && ex.response.data.message) {
        codeGenerationResult = ex.response.status;
        authenticationError = ex.response.data.message;
      } else {
        codeGenerationResult = ex.response ? ex.response.status : 500;
        authenticationError = ex.response ? ex.response.statusText : 'Server error';
        isError = true;
      }
    }
  }

  // This bit of mess is here because I haven't been able to make
  // typescript and Next happy at the same time. 
  if (urlCode && !isError) {
    return {
      props: {
        urlCode,
        codeGenerationResult,
        authenticationError
      }
    }
  }
  return {
    props: {
      codeGenerationResult,
      authenticationError,
      error: isError ? error : null,
    }
  };
}

export default IndexPage;
