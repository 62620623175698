import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

import { Box, BoxHeader } from '../Layout/Box';
import { Paragraph } from '../Typography/Typography';

const StatusIcon = styled.img`
  width: 24px;
  flex-shrink:0;
  margin-right: ${theme.spacingValues.xxs}px;
`;

const StyledDiv = styled.div`
  display:flex;
  flex-direction: column;
`;

const StyledBoxHeader = styled(BoxHeader)`
  align-items: flex-start;
  font-size: 14px;
`;

const CookieWarning: React.FC = () => (
  <Box maxWidth={340} >
    <StyledBoxHeader bgcolor="rgba(46, 82, 153, 0.1);">
      <StatusIcon width="24px" alt='' src={`../../static/info.svg`} />
      <StyledDiv>
        <Paragraph fontSize={theme.fontSize.xs} bold  >
          The Grants Tracker application uses cookies
        </Paragraph>
        <Paragraph fontSize={theme.fontSize.xs}  marginTop={12}>
          Please make sure cookies are enabled in your browser.
        </Paragraph>
      </StyledDiv>
    </StyledBoxHeader>
  </Box>
);

export default CookieWarning;


