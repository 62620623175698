/* eslint-disable import/extensions */
import styled from 'styled-components';

import theme from '../theme';

interface BoxHeaderProps {
  readonly inverted?: boolean;
  readonly bgcolor: string;
  readonly centerAlign?: boolean;
}

export const BoxHeader = styled.div<BoxHeaderProps>`
  padding: ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs};
  background-color: ${({ bgcolor }): string => (bgcolor || theme.colors.clickableBlue)};
  font-size: 19px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: ${({centerAlign}): string => centerAlign ? 'center' : ''};
  & h2 {
    margin: 0;
  }
`;

interface BoxProps {
  inset?: boolean;
  shadowed?: boolean;
  rounded?: boolean;
  maxWidth?: number;
  key?: string | number;
}

export const Box = styled.div<BoxProps>`
  background-color: ${theme.colors.white};
  margin: ${({ inset }): string => (inset ? `${theme.spacing.l} ${theme.spacing.xs}` : '0')};
  box-shadow: ${({ inset, shadowed }): string => (inset || shadowed ? '6px 6px 24px rgba(0, 0, 0, 0.06)' : 'none')};
  border-radius: ${({ inset, rounded }): string => (inset || rounded ? '4px' : '0')};
  ${BoxHeader}{
    border-radius: ${({ inset, rounded }): string => (inset || rounded ? '4px 4px 0 0' : '0')};
  }
  max-width: 100%;
  @media (min-width: 481px) {
    max-width: ${({ maxWidth }): string => (maxWidth ? `${maxWidth}px` : '')};
  }
`;

interface BoxContentProps {
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  shadowed?: boolean;
  rounded?: boolean;
  className?: string;
  key?: string;
}

export const BoxContent = styled.div<BoxContentProps>`
  padding-left: ${({ paddingLeft }): number => (paddingLeft !== undefined ? paddingLeft : theme.spacingValues.xs)}px;
  padding-right: ${({ paddingRight }): number => (paddingRight !== undefined ? paddingRight : theme.spacingValues.xs)}px;
  padding-top: ${({ paddingTop }): number => (paddingTop !== undefined ? paddingTop : theme.spacingValues.xs)}px;
  padding-bottom: ${({ paddingBottom }): number => (paddingBottom !== undefined ? paddingBottom : theme.spacingValues.xs)}px;
  & p:first-child {
    margin-top:0;
  }
  & p:last-child {
    margin-bottom:0;
  }
  &.hidden {
    display: none;
  }
`;

export const BoxFooter = styled.div`
  border-top: 1px solid ${theme.colors.lightGrey};
`;
