'use strict';

Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationErrors = exports.ApplicationStatusCode = exports.StatusStage = exports.FilePhaseState = exports.FilePhase = exports.ConsentStatus = void 0;
var ConsentStatus;
(function (ConsentStatus) {
    ConsentStatus["Y"] = "Y";
    ConsentStatus["N"] = "N";
})(ConsentStatus = exports.ConsentStatus || (exports.ConsentStatus = {}));
var FilePhase;
(function (FilePhase) {
    FilePhase["TERMINATION"] = "TERMINATION";
    FilePhase["APPEAL"] = "APPEAL";
    FilePhase["TRANSFER"] = "TRANSFER";
    FilePhase["APPLICATION"] = "APPLICATION";
})(FilePhase = exports.FilePhase || (exports.FilePhase = {}));
var FilePhaseState;
(function (FilePhaseState) {
    FilePhaseState["ACTIVE"] = "ACTIVE";
    FilePhaseState["DORMANT"] = "DORMANT";
})(FilePhaseState = exports.FilePhaseState || (exports.FilePhaseState = {}));
var StatusStage;
(function (StatusStage) {
    StatusStage["RECEIVE"] = "receive";
    StatusStage["ASSESS"] = "assess";
    StatusStage["DECIDE"] = "decide";
})(StatusStage = exports.StatusStage || (exports.StatusStage = {}));
var ApplicationStatusCode;
(function (ApplicationStatusCode) {
    ApplicationStatusCode["SAVED"] = "SAVED";
    ApplicationStatusCode["ASSESSED"] = "ASSESSED";
    ApplicationStatusCode["REQUISITION_GENERATED"] = "REQUISITION_GENERATED";
    ApplicationStatusCode["REQUISITION_RECEIVED"] = "REQUISITION_RECEIVED";
    ApplicationStatusCode["REQUISITION_GENERATED_ADDITIONAL"] = "REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["DETERMINED_APPROVED"] = "DETERMINED_APPROVED";
    ApplicationStatusCode["DETERMINED_REFUSED"] = "DETERMINED_REFUSED";
    ApplicationStatusCode["APPEAL_REQUEST_RECEIVED"] = "APPEAL_REQUEST_RECEIVED";
    ApplicationStatusCode["APPEAL_ASSESSING"] = "APPEAL_ASSESSING";
    ApplicationStatusCode["APPEAL_REQUISITION_GENERATED"] = "APPEAL_REQUISITION_GENERATED";
    ApplicationStatusCode["APPEAL_REQUISITION_RECEIVED"] = "APPEAL_REQUISITION_RECEIVED";
    ApplicationStatusCode["APPEAL_REQUISITION_GENERATED_ADDITIONAL"] = "APPEAL_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["LARC_REFERRAL"] = "LARC_REFERRAL";
    ApplicationStatusCode["LARC_REQUISITION_GENERATED"] = "LARC_REQUISITION_GENERATED";
    ApplicationStatusCode["APPEAL_FINALISED_REFUSED"] = "APPEAL_FINALISED_REFUSED";
    ApplicationStatusCode["APPEAL_FINALISED_APPROVED"] = "APPEAL_FINALISED_APPROVED";
    ApplicationStatusCode["TERMINATION_ISSUED"] = "TERMINATION_ISSUED";
    ApplicationStatusCode["TERMINATION_RESPONSE_RECEIVED"] = "TERMINATION_RESPONSE_RECEIVED";
    ApplicationStatusCode["TERMINATION_REQUISITION_GENERATED"] = "TERMINATION_REQUISITION_GENERATED";
    ApplicationStatusCode["TERMINATION_REQUISITION_RECEIVED"] = "TERMINATION_REQUISITION_RECEIVED";
    ApplicationStatusCode["TERMINATION_REQUISITION_GENERATED_ADDITIONAL"] = "TERMINATION_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["TERMINATION_DECISION_APPROVED"] = "TERMINATION_DECISION_APPROVED";
    ApplicationStatusCode["TERMINATION_DECISION_REFUSED"] = "TERMINATION_DECISION_REFUSED";
    ApplicationStatusCode["TRANSFER_RECEIVED"] = "TRANSFER_RECEIVED";
    ApplicationStatusCode["TRANSFER_ASSESSED"] = "TRANSFER_ASSESSED";
    ApplicationStatusCode["TRANSFER_REQUISITION_GENERATED"] = "TRANSFER_REQUISITION_GENERATED";
    ApplicationStatusCode["TRANSFER_REQUISITION_RECEIVED"] = "TRANSFER_REQUISITION_RECEIVED";
    ApplicationStatusCode["TRANSFER_REQUISITION_GENERATED_ADDITIONAL"] = "TRANSFER_REQUISITION_GENERATED_ADDITIONAL";
    ApplicationStatusCode["TRANSFER_APPROVED"] = "TRANSFER_APPROVED";
    ApplicationStatusCode["TRANSFER_REFUSED"] = "TRANSFER_REFUSED";
})(ApplicationStatusCode = exports.ApplicationStatusCode || (exports.ApplicationStatusCode = {}));
var AuthenticationErrors;
(function (AuthenticationErrors) {
    AuthenticationErrors["PIN_TOO_OLD"] = "PIN_TOO_OLD";
    AuthenticationErrors["PIN_INCORRECT"] = "PIN_INCORRECT";
    AuthenticationErrors["FILE_LOCKED"] = "FILE_LOCKED";
    AuthenticationErrors["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    AuthenticationErrors["NO_CONSENT"] = "NO_CONSENT";
    AuthenticationErrors["ALREADY_UNSUBSCRIBED"] = "ALREADY_UNSUBSCRIBED";
    AuthenticationErrors["ALREADY_SUBSCRIBED"] = "ALREADY_SUBSCRIBED";
})(AuthenticationErrors = exports.AuthenticationErrors || (exports.AuthenticationErrors = {}));
